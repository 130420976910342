import { CircularProgress, CircularProgressProps } from "@mui/material";
import { useEffect, useState } from "react";

export interface IPageLoadingSpinnerProps extends CircularProgressProps {}

export function PageLoadingSpinner({
  size: customSize,
  ...props
}: IPageLoadingSpinnerProps): JSX.Element {
  // NOTE: This component is not ideal and you should use an individualized loading implementation for your usage. eg, create a loading skeleton for your table, or a loading spinner for your form.

  const [showSpinner, setShowSpinner] = useState<boolean>(true);

  const size = customSize || "3rem";
  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 1500);
  }, []);

  if (!showSpinner) {
    return <></>;
  }

  return (
    <CircularProgress
      size={size}
      style={{
        position: "absolute",
        top: `calc(50% - ${size} / 2)`,
        left: `calc(50% - ${size} / 2)`,
      }}
      {...props}
    />
  );
}
